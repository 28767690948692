import React from 'react'
import { Box, TextField } from '@mui/material'

const ApplyOnline = () => {
  return (
    <section
      style={{
        backgroundColor: '#E6E6E6',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: 5,
          //   textAlign: 'center',
          //   margin: 'auto',
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '40%' },
            bgcolor: '#fff',
            p: '40px 10% 40px 40px',
          }}
        >
          <form>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <TextField
                InputLabelProps={{
                  sx: { fontSize: '20px', fontWeight: 500, color: 'black' },
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  padding: '10px 5px',
                }}
                label='Your Name'
                variant='standard'
              />
              <TextField
                InputLabelProps={{
                  sx: { fontSize: '20px', fontWeight: 500, color: 'black' },
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  padding: '10px 5px',
                }}
                label='Your Email-id'
                variant='standard'
              />
              <TextField
                InputLabelProps={{
                  sx: { fontSize: '20px', fontWeight: 500, color: 'black' },
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  padding: '10px 5px',
                }}
                label='Your phone number'
                variant='standard'
              />
              <TextField
                InputLabelProps={{
                  sx: { fontSize: '20px', fontWeight: 500, color: 'black' },
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  padding: '10px 5px',
                }}
                label='Location'
                variant='standard'
              />
              <TextField
                InputLabelProps={{
                  sx: { fontSize: '20px', fontWeight: 500, color: 'black' },
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  padding: '10px 5px',
                }}
                label='Message'
                variant='standard'
              />
            </Box>
            <Box
              sx={{
                cursor: 'pointer',
                padding: '8px 40px',
                bgcolor: '#F43E04',
                fontSize: { xs: '20px', md: '25px' },
                color: 'white',
                textAlign: 'center',
                mt: 3,
                marginX: {
                  xs: '',
                  md: '',
                  lg: 2,
                },
              }}
            >
              Submit Message
            </Box>
          </form>
        </Box>
      </Box>
    </section>
  )
}

export default ApplyOnline

import React from 'react'
import { Box, TextField } from '@mui/material'
import SectionTitle from './Shared/SectionTitle'

const ContactUs = () => {
  const titleAndDetails = {
    title: 'Contact Us',
    details:
      'We offered industries leading course for medical coding & Medical billing course for medical coding & Medical billing at',
  }
// ['Home', 'Service', 'Course', 'About Us', 'Contact']
  return (
    <section id='Contact' className='container'>
      <SectionTitle titleAndDetails={titleAndDetails} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          gap: 10,
          marginY: { xs: 5, md: 10 },
        }}
      >
        {/* Img Section */}
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            maxWidth: '600px',
          }}
        >
          <img className='img-fluid' src='/assets/contact_us_bg.png' alt='' />
        </Box>

        {/* Form Side */}
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
          }}
        >
          <form>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '25px',
              }}
            >
              <TextField
                InputLabelProps={{
                  sx: { fontSize: '25px', fontWeight: 500, color: 'black' },
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  padding: '20px 5px',
                }}
                label='First Name'
                variant='standard'
              />
              <TextField
                InputLabelProps={{
                  sx: { fontSize: '25px', fontWeight: 500, color: 'black' },
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  padding: '20px 5px',
                }}
                label='Mail-id'
                variant='standard'
              />
              <TextField
                InputLabelProps={{
                  sx: { fontSize: '25px', fontWeight: 500, color: 'black' },
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  padding: '20px 5px',
                }}
                label='Phone Number'
                variant='standard'
              />
              <TextField
                InputLabelProps={{
                  sx: { fontSize: '25px', fontWeight: 500, color: 'black' },
                  shrink: true,
                }}
                sx={{
                  width: '100%',
                  padding: '20px 5px',
                }}
                label='Message'
                variant='standard'
              />
            </Box>
            <Box
              sx={{
                cursor: 'pointer',
                padding: '10px 30px',
                bgcolor: '#F43E04',
                fontSize: '25px',
                color: 'white',
                textAlign: 'center',
                marginX: {
                  xs: '',
                  md: '',
                  lg: 10,
                },
              }}
            >
              Contact Us
            </Box>
          </form>
        </Box>
      </Box>
    </section>
  )
}

export default ContactUs

import React from 'react'
import { Box } from '@mui/material'
import Banner from '../components/Banner'
import OurFeaturedService from '../components/OurFeaturedService'
import CourseOffered from '../components/CourseOffered'
import AboutUs from '../components/AboutUs'
import OurSpecialization from '../components/OurSpecialization'
import OurClients from '../components/OurClients'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import Navbar from '../components/Shared/Navbar'

const Home = () => {
  return (
    <Box>
      <Navbar />
      <Banner />
      <OurFeaturedService />
      <CourseOffered />
      <AboutUs />
      <OurSpecialization />
      <OurClients />
      <ContactUs />
      <Footer />
    </Box>
  )
}

export default Home

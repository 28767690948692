import React, { useState } from 'react'
import SectionTitle from './Shared/SectionTitle'
import { Box } from '@mui/material'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useNavigate } from 'react-router-dom'

const CourseOffered = () => {
  const titleAndDetails = {
    title: 'Course offered',
    details:
      'We offered industries leading course for medical coding & Medical billing course for medical coding & Medical billing',
  }

  const [value, setValue] = useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const navigator = useNavigate()

  const [selectedItem, setSelectedItem] = useState(1)

  const items = ['CMS', 'ACMC', 'COC', 'CIC', 'CPC']

  return (
    <section id='Course' className='container'>
      <SectionTitle titleAndDetails={titleAndDetails} />

      <Box>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className=' mt-4 subscriptions-box'>
            <Box className='d-flex justify-content-center align-items-center '>
              {items.map((item, index) => {
                return (
                  <Box
                    className='style-sub'
                    sx={{
                      color: selectedItem == index ? '#000' : '#FFF ',
                      background: selectedItem == index && '#fff',
                      cursor: 'pointer',
                      fontSize: '16px',
                      fontWeight: 500,
                      padding: {
                        xs: '',
                        sm: '13px 30px',
                      },
                    }}
                    onClick={() => setSelectedItem(index)}
                  >
                    {item}
                  </Box>
                )
              })}
            </Box>
          </div>
        </div>
      </Box>

      <Box className='row flex-column flex-md-row gap-3 my-5 align-items-center text-center'>
        <Box onClick={() => navigator('/course-detail/bmct')} className='col'>
          <img className='img-fluid' src='/assets/doctor.png' alt='' />
        </Box>
        <Box onClick={() => navigator('/course-detail/pcct')} className='col'>
          <img className='img-fluid' src='/assets/stethes.png' alt='' />
        </Box>
        <Box
          onClick={() => navigator('/course-detail/placements')}
          className='col'
        >
          <img className='img-fluid' src='/assets/medical-book.png' alt='' />
        </Box>
      </Box>
    </section>
  )
}

export default CourseOffered

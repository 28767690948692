import React from 'react'
import { Grid, Typography, Box } from '@mui/material'

const Footer = () => {
  const FooterLinkStyle = {
    fontWeight: 700,
  }
  const FooterTitleStyle = {
    fontWeight: 700,
    fontSize: 25,
  }

  return (
    <section>
      <Grid
        sx={{
          bgcolor: '#F43E04',
          paddingY: 5,
        }}
        container
      >
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'start' },
            alignItems: 'center',
          }}
          xs={12}
          sm={4}
        >
          <Box
            sx={{
              marginLeft: {
                sx: '',
                sm: 2,
                md: 10,
              },
            }}
          >
            <img className='' src='/assets/footer-logo.png' alt='' />
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            gap: 1.5,
            justifyContent: 'center',
            paddingY: { xs: 5, sm: 0 },
          }}
          xs={12}
          sm={4}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            <Typography sx={FooterTitleStyle}>Quick Links</Typography>
            <Typography sx={FooterLinkStyle}>Course</Typography>
            <Typography sx={FooterLinkStyle}>Training</Typography>
            <Typography sx={FooterLinkStyle}>About Us</Typography>
            <Typography sx={FooterLinkStyle}>Blog</Typography>
            <Typography sx={FooterLinkStyle}>Contact Us</Typography>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: {
              xs: 'center',
              sm: 'end',
            },
            alignItems: 'center',
          }}
          xs={12}
          sm={4}
        >
          <Box
            sx={{
              marginRight: {
                sx: '',
                sm: 2,
                md: 10,
              },
            }}
          >
            <Typography sx={FooterTitleStyle}>Follow Us On</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                marginY: 2,
              }}
            >
              <img src='/assets/Facebook.png' alt='' />
              <img src='/assets/Twitter.png' alt='' />
            </Box>
            <Typography sx={FooterLinkStyle}>Terms and condition</Typography>
          </Box>
        </Grid>
      </Grid>
    </section>
  )
}

export default Footer

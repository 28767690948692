import React from 'react'
import SectionTitle from './Shared/SectionTitle'

const OurClients = () => {
  const titleAndDetails = {
    title: 'Our Clients',
    details:
      'We offered industries leading course for medical coding & Medical billing course for medical coding & Medical billing',
  }
  return (
    <section>
      <SectionTitle isContainer titleAndDetails={titleAndDetails} />

      <marquee
        className='my-5'
        behavior=''
        direction='right'
        style={{
          backgroundColor: '#FFDDD2',
        }}
      >
        {[
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        ].map(() => (
          <img src='/assets/clients.png' alt='' />
        ))}
      </marquee>
    </section>
  )
}

export default OurClients

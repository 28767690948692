import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import AdbIcon from '@mui/icons-material/Adb'
import { useNavigate } from 'react-router-dom'

const pages = ['Home', 'Service', 'Course', 'About Us', 'Contact']

function Navbar() {
  const navigate = useNavigate()

  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [selectedMenu, setSelectedMenu] = React.useState('Home')

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <section>
      <Box
        sx={{
          bgcolor: '#F43E04',
          paddingTop: '8px',
        }}
      />{' '}
      <AppBar
        sx={{
          bgcolor: '#fff',
          padding: { xs: '0 10px', md: '0 20px 0 60px' },
        }}
        position='static'
      >
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Typography
              variant='h6'
              noWrap
              component='a'
              href='#app-bar-with-responsive-menu'
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img height={50} src='/assets/app-logo.png' alt='' />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                //   color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign='center'>{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Typography
              variant='h5'
              noWrap
              component='a'
              href='#app-bar-with-responsive-menu'
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {/* <img src='/assets/app-logo.png' alt='' /> */}
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: 'center',
                display: { xs: 'none', md: 'flex' },
              }}
            >
              {pages.map((page) => (
                <Button
                  href={`#${page}`}
                  key={page}
                  onClick={() => {
                    setSelectedMenu(page)
                    handleCloseNavMenu()
                    // navigate(`#${page}`)
                  }}
                  //   sx={{}}
                  sx={{
                    my: 2,
                    color: 'black',
                    display: 'block',
                    borderRadius: '0',
                    borderBottom: page === selectedMenu && '3px solid red',
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Box
                onClick={() => navigate('/online-apply')}
                sx={{
                  bgcolor: '#F43E04',
                  textAlign: 'center',
                  padding: { xs: '3px 10px', md: '3px 40px' },
                  fontSize: { xs: 15, md: 30 },
                  color: '#fff',
                  // mt: 5,
                  cursor: 'pointer',
                  maxWidth: { md: '350px' },
                }}
              >
                Apply Online
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </section>
  )
}
export default Navbar

import React from 'react'
import { Typography, Box } from '@mui/material'

const SectionTitle = ({ titleAndDetails, isContainer }) => {
  const { title, details } = titleAndDetails

  return (
    <Box
      className={isContainer ? 'container' : ''}
      sx={{
        textAlign: 'center',
        marginX: 'auto',
        maxWidth: '550px',
      }}
    >
      <Typography
        sx={{
          fontSize: 40,
          fontWeight: 'bold',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '20px',
          color: '#6c6565',
        }}
      >
        {details}
      </Typography>
    </Box>
  )
}

export default SectionTitle

// Root Bootstrap Import
import 'bootstrap/dist/css/bootstrap.min.css'
import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import ApplyOnline from './components/ApplyOnline'
import Bmct from './components/CourseDetail/Bmct'
import Pcct from './components/CourseDetail/Pcct'
import Placement from './components/CourseDetail/Placements'

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/online-apply' element={<ApplyOnline />} />

      <Route path='/course-detail/bmct' element={<Bmct />} />
      <Route path='/course-detail/pcct' element={<Pcct />} />
      <Route path='/course-detail/placements' element={<Placement />} />
    </Routes>
  )
}

export default App

import React from 'react'
import SectionTitle from './Shared/SectionTitle'
import { Box, Grid, Typography } from '@mui/material'

// Data

const titleAndDetails = {
  title: 'Our Featured Service',
  details:
    'Provides training and equips them with sound  knowledgein all domain and does its best to create job opportunities.',
}
const data = [
  {
    id: 1,
    title: 'Online Training',
    img: '/assets/pc-image.png',
    detail:
      'Is for those who are at remote locations ,with an intention to provide services for all those aspirants who want to be part of the core department.',
  },
  {
    id: 2,
    title: 'Job Training',
    img: '/assets/job-icon.png',
    detail:
      'Is for those who are at remote locations ,with an intention to provide services for all those aspirants who want to be part of the core department.',
  },
  {
    id: 3,
    title: 'Placements',
    img: '/assets/pc-with-location.png',
    detail:
      'Is for those who are at remote locations ,with an intention to provide services for all those aspirants who want to be part of the core department.',
  },
]

const OurFeaturedService = () => {
  return (
    <section id='Service' className='container'>
      <SectionTitle titleAndDetails={titleAndDetails} />
      <Box>
        <Grid
          container
          spacing={5}
          sx={{
            marginY: 8,
          }}
        >
          {data?.map((item) => {
            const { id, title, img, detail } = item
            return (
              <Grid
                key={id}
                item
                xs={12}
                sm={6}
                lg={4}
                sx={{
                  pb: 5,
                  ':hover': {
                    backgroundColor: '#F43E04',
                    color: 'white',
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '28px',
                      fontWeight: 'bold',
                    }}
                  >
                    {title}
                  </Typography>
                  <Box
                    sx={{
                      p: 4,
                      bgcolor: '#E6E6E6',
                      borderRadius: '50%',
                    }}
                  >
                    <img src={img} alt='' />
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '22px',
                    }}
                  >
                    {detail}
                  </Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </section>
  )
}

export default OurFeaturedService

import React from 'react'
import SectionTitle from './Shared/SectionTitle'
import { Box, Typography } from '@mui/material'

const OurSpecialization = () => {
  const titleAndDetails = {
    title: 'Our Specialization',
    details: '',
  }
  const SpecializationCard = [
    {
      id: 1,
      title: 'Medical coding',
      img: '/assets/medical-coding.png',
    },
    {
      id: 2,
      title: 'Medical Billing',
      img: '/assets/medical-billing.png',
    },
    {
      id: 3,
      title: 'Healthcare Training',
      img: '/assets/health-insurance.png',
    },
  ]

  return (
    <section>
      <SectionTitle isContainer titleAndDetails={titleAndDetails} />
      <Box
        sx={{
          marginX: {
            xs: 5,
            md: 15,
          },
          marginY: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            className='row '
            sx={{
              gap: {
                xs: 5,
                md: 5,
                lg: 10,
                xl: 10,
              },
            }}
          >
            {SpecializationCard.map((i) => {
              const { id, title, img } = i
              return (
                <Box
                  key={id}
                  className='col'
                  sx={{
                    maxWidth: { xs: '100%', md: '350px' },
                    minWidth: '250px',
                    gap: 2,
                    p: 3,
                    borderRadius: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    bgcolor: '#E6E6E6',
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: '#F43E04',
                      p: 3,
                      borderRadius: '50%',
                    }}
                  >
                    <img src={img} alt='' />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              )
            })}
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              fontSize: { xs: '20px', md: '30px' },
              fontWeight: 'bold',
              lineHeight: 2,
              marginY: {
                xs: 5,
                md: 10,
              },
            }}
          >
            <ul>
              <li>
                Faculties are having 20+ years of real-time coding experience in
                multi-specialty coding with multiple certification
              </li>
              <li>Individual attention to all the students</li>
              <li>All trained students shall be placed in reputed companies</li>
              <li>
                Not only we will teach you the skills and knowledge, but we will
                also provide you tips and guidance for achieving your career
                goals
              </li>
            </ul>
          </Box>
          <Typography
            sx={{
              fontSize: { xs: '20px', md: '30px' },
              textAlign: 'center',
              color: '#6C6565',
            }}
          >
            All of us at SSA (Sun Shine Academy), look forward to helping you
            get the career training you need to get ahead in life. Enroll today
            – and take the next step toward a brighter tomorrow.
          </Typography>
          <Box
            sx={{
              bgcolor: '#F43E04',
              padding: '10px 20px',
              margin: '40px auto',
              fontSize: 25,
              color: 'white',
              textAlign: 'center',
              maxWidth: '200px',
            }}
          >
            Enroll Now
          </Box>
        </Box>
      </Box>
    </section>
  )
}

export default OurSpecialization

import React from 'react'
import { Box, TextField, Typography } from '@mui/material'

const Placement = () => {
  return (
    <section
      style={{
        backgroundColor: '#E6E6E6',
        minHeight: '100vh',
      }}
    >
      <Typography
        sx={{
          fontSize: 35,
          pt: 5,
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Placements
      </Typography>
      <Box
        className='container'
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '60%' },
            fontSize: '20px',
            lineHeight: '34px',
            fontWeight: 'bold',
          }}
        >
          <ul>
            <li>
              Get a complete understanding about us healthcare & Medical coding
            </li>
            <li>
              Get the practical knowledge on using ICD 10 CM, CPT and HCPCS
            </li>
            <li>
              Trained to face the interview with payer & Providers coding
              organizations
            </li>
            <li>Get an opportunity to join as a fresher</li>
            <li>Get placement Assistance from sunshine academy</li>
          </ul>
        </Box>
        <Box
          sx={{
            width: { xs: '100%', md: '40%' },
            // display: 'flex',
            pt: 2,
          }}
        >
          <Box
            sx={{
              //   width: { xs: '100%', md: '40%' },
              bgcolor: '#fff',
              p: '40px 10% 40px 40px',
            }}
          >
            <form>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <TextField
                  InputLabelProps={{
                    sx: { fontSize: '20px', fontWeight: 500, color: 'black' },
                    shrink: true,
                  }}
                  sx={{
                    width: '100%',
                    padding: '10px 5px',
                  }}
                  label='Your Name'
                  variant='standard'
                />
                <TextField
                  InputLabelProps={{
                    sx: { fontSize: '20px', fontWeight: 500, color: 'black' },
                    shrink: true,
                  }}
                  sx={{
                    width: '100%',
                    padding: '10px 5px',
                  }}
                  label='Your Email-id'
                  variant='standard'
                />
                <TextField
                  InputLabelProps={{
                    sx: { fontSize: '20px', fontWeight: 500, color: 'black' },
                    shrink: true,
                  }}
                  sx={{
                    width: '100%',
                    padding: '10px 5px',
                  }}
                  label='Your phone number'
                  variant='standard'
                />
                <TextField
                  InputLabelProps={{
                    sx: { fontSize: '20px', fontWeight: 500, color: 'black' },
                    shrink: true,
                  }}
                  sx={{
                    width: '100%',
                    padding: '10px 5px',
                  }}
                  label='Location'
                  variant='standard'
                />
                <TextField
                  InputLabelProps={{
                    sx: { fontSize: '20px', fontWeight: 500, color: 'black' },
                    shrink: true,
                  }}
                  sx={{
                    width: '100%',
                    padding: '10px 5px',
                  }}
                  label='Message'
                  variant='standard'
                />
              </Box>
              <Box
                sx={{
                  cursor: 'pointer',
                  padding: '8px 40px',
                  bgcolor: '#F43E04',
                  fontSize: { xs: '20px', md: '25px' },
                  color: 'white',
                  textAlign: 'center',
                  mt: 3,
                  marginX: {
                    xs: '',
                    md: '',
                    lg: 2,
                  },
                }}
              >
                Submit Message
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </section>
  )
}

export default Placement

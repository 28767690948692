import React from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const Banner = () => {
  const navigate = useNavigate()

  return (
    <section
      id='Home'
      style={{
        minHeight: '95vh',
        backgroundColor: '#E6E6E6',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          gap: 4,
          mx: 3,
        }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '50%',
              lg: '60%',
            },
            marginLeft: {
              xs: '',
              md: '',
              lg: 10,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              color: '#F43E04 ',
              gap: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 20, md: 25 },
              }}
            >
              #Medicalcoder
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 20, md: 25 },
              }}
            >
              #Healthcare
            </Typography>
          </Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: 25, md: 45 },
              marginTop: 5,
              marginBottom: 2,
            }}
          >
            BECOME A MEDICAL CODING SPECIALIST ENJOY A REWARDING CAREER!
          </Typography>

          <Typography
            sx={{
              fontSize: { xs: 18, md: 20 },
              color: '#6C6565',
              fontWeight: 'bold',
            }}
          >
            Provides training and equips them with sound knowledge in all domain
            and does its best to create job opportunities.
          </Typography>

          <Box
            onClick={() => navigate('/online-apply')}
            sx={{
              bgcolor: '#F43E04',
              textAlign: 'center',
              padding: '10px 40px',
              fontSize: 30,
              color: '#fff',
              mt: 5,
              cursor: 'pointer',
              maxWidth: { md: '350px' },
            }}
          >
            Apply Online
          </Box>
        </Box>
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '50%',
              lg: '40%',
            },
          }}
        >
          <img className='img-fluid' src='/assets/main-banner-bg.png' alt='' />
        </Box>
      </Box>
    </section>
  )
}

export default Banner

import React from 'react'
import SectionTitle from './Shared/SectionTitle'
import { Box } from '@mui/material'

const AboutUs = () => {
  const titleAndDetails = {
    title: 'About Us',
    details:
      'We offered industries leading course for medical coding & Medical billing course for medical coding & Medical billing',
  }

  return (
    <section id='About Us'>
      <SectionTitle isContainer titleAndDetails={titleAndDetails} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          marginX: { xs: 5, md: 10 },
          marginY: { xs: 10, md: 5 },
          alignItems: 'center',
          textAlign: { xs: 'justify', sm: 'start' },
          justifyContent: 'space-between',
          gap: 10,
        }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '60%',
              lg: '70%',
            },
            fontSize: {
              xs: '20px',
              md: '30px',
            },
            fontWeight: 'bold',
          }}
        >
          “The right skill-set is critical for today’s high-quality job market”.
          At SSA (Sun Shine Academy) you will receive up-to-date career skills
          and the knowledge needed to succeed in the job market of today and
          tomorrow. Sunshine Academy is a leading organization in Chennai aiming
          to serve the society through Coaching
        </Box>
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '40%',
              lg: '30%',
            },
          }}
        >
          <img className='img-fluid' src='/assets/about-us-ref.png' alt='' />
        </Box>
      </Box>
    </section>
  )
}

export default AboutUs
